import React from "react";
import { UnauthenticatedTemplate } from "@azure/msal-react";
import { PageLayout } from "./PageLayout";
import "../styles/App.css";
import AlertMessage from "./AlertMessage";

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  return (
    <div className="App">
      <UnauthenticatedTemplate>
        <div className="dashboard-main">
          <div className="dashboard-sub-1">
            <div className="mainImage"></div>
          </div>
          <div className="dashboard-sub-2">
            <AlertMessage variant={"success"} showMessage={true}>
              You are successfully logged out, You may close the browser.
            </AlertMessage>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function Signin() {
  return (
    <PageLayout>
      <MainContent />
    </PageLayout>
  );
}
