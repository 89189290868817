import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/App.css";

import Profile from "./components/Profile";
import Dashboard from "./components/Dashboard";
import Signin from "./components/Signin";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/profile/:url" element={<Profile />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
