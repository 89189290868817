import VCard from "vcard-creator";
import mySvg from "../images/addcontact_button.svg";
import celcom_logo from "../images/celcom_logo.svg";
import dialog from "../images/dialog_logo.svg";
import xlaxiat from "../images/xlaxiata_logo.svg";

import smart_logo from "../images/smart_logo.svg";
import robi_logo from "../images/robi_logo.svg";
import ncell_logo from "../images/ncell_logo.svg";

import edotco_logo from "../images/EDOTCO Logo_ Full_Color-01.svg";
import ada_logo from "../images/ada_logo.svg";
import axiatadigitallabs_logo from "../images/axiatadigitallabs_logo.svg";
import boost_logo from "../images/boost_logo.svg";
import link_net_logo from "../images/linknet_logo.svg";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { browserName, osName } from "react-device-detect";
import AlertMessage from "./AlertMessage";
import main_logo from "../images/Logo.svg";

const Profile = ({ qr_url }) => {
  const getEncryptedTxt = (url) => {
    const path = url.split("/");
    console.log(path, "path");
    return path[4];
  };

  let { url } = useParams();
  url = qr_url ? getEncryptedTxt(qr_url) : url;
  const [userProfile, setUserProfile] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  useEffect(() => {
    let headers = new Headers({ "Access-Control-Allow-Origin": "*" });
    headers.append("Content-Type", "application/json");
    const body = JSON.stringify({
      encryptedRequest: {
        encryptedText: decodeURIComponent(url),
      },
      userAgentDTO: {
        // ipaddress: "10.10.12.19",
        // email: "hello@gmail.com",
        deviceType: browserName,
        operatingSystem: osName,
      },
    });
    fetch("/retrievebusinesscardapi/", {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "res");
        setUserProfile(res);
      })
      .catch((e) => {
        setErrorMessage(true);
      });
  }, []);

  let designation = "testDest";
  let destarray = designation.split("$");
  let destarraylen = destarray.length;
  let phone = "898898989";
  let tellink = "tel:";
  tellink = tellink.concat(phone);

  const SaveContact = (e) => {
    e.preventDefault();
    console.log("The link was clicked.");
    // Define a new vCard
    const myVCard = new VCard();
    myVCard;
    // Add personal data
    //.addName(userProfile.firstName)
    // Add work data
    //.addCompany(userProfile.designation)
    //.addJobtitle(userProfile.designation)
    //.addEmail(userProfile.email)
    //.addPhoneNumber(userProfile.mobile, "PREF;WORK");
    //.addPhoneNumber(userProfile.mobile, "WORK");
    if (userProfile.firstName) {
      let name = userProfile.firstName;
      name = userProfile.secondName
        ? userProfile.firstName + " " + userProfile.secondName
        : name;
      myVCard.addName(userProfile.firstName);
    }
    if (userProfile.designation) {
      myVCard.addCompany(userProfile.designation);
    }
    if (userProfile.mobile) {
      myVCard.addPhoneNumber(userProfile.mobile, "PREF;WORK");
    }
    if (userProfile.email) {
      myVCard.addEmail(userProfile.email);
    }
    //console.log(myVCard.toString());
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/x-vcard;charset=utf-8," +
        encodeURIComponent(myVCard.toString())
    );
    element.setAttribute("download", userProfile.firstName + ".vcf");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };
  return (
    <div className="profile">
      {!errorMessage && (
        <div className="card">
          <div className="logo">
            <img src={main_logo} className="topLogo" alt="logo" />
          </div>
          <div className="div-1">
            <img
              src={`data:image/png;base64,${userProfile.qrcode}`}
              className="barcodeImage"
              alt="qr code"
            />
          </div>
          {userProfile.firstName && (
            <h1 className="title">{userProfile.firstName}</h1>
          )}
          {userProfile.secondName && (
            <h1 className="title secondName">{userProfile.secondName}</h1>
          )}
          <p
            className="subTitle"
            style={{ paddingRight: "20px", paddingLeft: "20px" }}
          >
            {userProfile.designation}
          </p>
          {destarraylen > 1 && (
            <p
              className="subTitle1"
              style={{ paddingRight: "20px", paddingLeft: "20px" }}
            >
              {destarray[1]}
            </p>
          )}
          {destarraylen > 2 && (
            <p
              className="subTitle1"
              style={{ paddingRight: "20px", paddingLeft: "20px" }}
            >
              {destarray[2]}
            </p>
          )}
          <a
            className="addAccount"
            href="javascript:void(0)"
            onClick={SaveContact}
          >
            {" "}
            <img src={mySvg} alt="logo" />{" "}
          </a>
          <p className="subTitle paddLR40" style={{ marginTop: "5px" }}>
            {userProfile.mobile}{" "}
          </p>
          <p className="subTitle email paddLR40" style={{ margin: "0px" }}>
            {userProfile.email}
          </p>
          <p
            className="subTitle"
            style={{
              paddingRight: "20px",
              paddingLeft: "20px",
              marginTop: "5px",
            }}
          >
            Axiata Tower, 9 Jalan Stesen Sentral 5, Kuala Lumpur Sentral 50470,
            Kuala Lumpur, Malaysia
          </p>
          <div className="FooterLogos">
            {/* <img src={celcom_logo} className="companyLogo" alt="logo" /> */}
            <img src={xlaxiat} className="xlAxiataLogo" alt="logo" />
            <img src={link_net_logo} className="linkNetLogo" alt="logo" />
            <img src={dialog} className="dialogLogo" alt="logo" />
            <br />
            <img src={robi_logo} className="secondRowIcon" alt="logo" />
            <img src={smart_logo} className="secondRowIcon" alt="logo" />
            <img src={ncell_logo} className="secondRowIcon" alt="logo" />
            <br />
            <img src={boost_logo} className="boostLogo" alt="logo" />
            <img src={ada_logo} className="adaLogo" alt="logo" />
            <img
              src={axiatadigitallabs_logo}
              className="digitalLabsLogo"
              alt="logo"
            />
            <img src={edotco_logo} className="companyLogo edotco" alt="logo" />
            <h6 className="footerCss">www.axiata.com</h6>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="card">
          <AlertMessage
            variant={"danger"}
            onClose={() => setShowMessage(false)}
            showMessage={true}
          >
            Sorry Unable to process your request due to some technical problem
            right now, you may try again later!
          </AlertMessage>
        </div>
      )}
    </div>
  );
};

export default Profile;
