import Alert from "react-bootstrap/Alert";

function AlertMessage({ variant, showMessage, children, onClose }) {
  return (
    <>
      <Alert
        key={variant}
        variant={variant}
        show={showMessage}
        onClose={onClose}
      >
        {children}
      </Alert>
    </>
  );
}

export default AlertMessage;
