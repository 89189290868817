import React, { useState, useEffect, useRef } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { PageLayout } from "./PageLayout";
import { Spinner } from "react-bootstrap";
import "../styles/App.css";
import { Link } from "react-router-dom";
import { useMsalAuthentication } from "@azure/msal-react";
import {
  InteractionType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import AlertMessage from "./AlertMessage";
import { osName, browserName } from "react-device-detect";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Profile from "./Profile";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
import { toJpeg } from "html-to-image";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
  const { accounts } = useMsal();

  return (
    <>
      <h1 id="userTitle">
        <span className="headGray">
          Welcome <span id="username">{accounts[0].name}</span>
        </span>
      </h1>
    </>
  );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  const { instance, accounts } = useMsal();
  const [dashboardData, setDashboardData] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [show, setShow] = useState(false);
  const [showTarget1, setShowTarget1] = useState(false);
  const target = useRef(null);
  const target1 = useRef(null);

  const RequestProfileData = () => {
    if (accounts[0]) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          let header = new Headers({ "Access-Control-Allow-Origin": "*" });
          header.append("Authorization", `Bearer ${response.accessToken}`);
          var raw = JSON.stringify({
            deviceType: browserName,
            operatingSystem: osName,
          });
          header.append("Content-Type", "application/json");
          fetch("/dashboardapi/" + accounts[0].username, {
            method: "POST",
            headers: header,
            body: raw,
          })
            .then((res) => res.json())
            .then((res) => {
              setDashboardData(res);
            })
            .catch((error) => {
              setShowMessage(true);
            })
            .catch((error) => {
              setShowMessage(true);
            });
        })
        .catch((e) => {
          instance.loginRedirect(loginRequest).catch((e) => {
            console.log(e);
          });
        });
    }
  };
  const { login, result, error } = useMsalAuthentication("redirect");

  const generateQR = () => {
    if (accounts[0]) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          let header = new Headers({ "Access-Control-Allow-Origin": "*" });
          header.append("Authorization", `Bearer ${response.accessToken}`);
          var raw = JSON.stringify({
            // ipaddress: "10.10.10.145",
            // email: "hello@gmail.com",
            deviceType: browserName,
            operatingSystem: osName,
          });
          header.append("Content-Type", "application/json");
          fetch("/generateapi/" + accounts[0].username, {
            method: "POST",
            headers: header,
            body: raw,
          })
            .then((res) => res.json())
            .then((res) => {
              setDashboardData(res);
            })
            .catch((error) => {
              setShowMessage(true);
            });
        })
        .catch((error) => {
          setShowMessage(true);
        });
    }
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(`${dashboardData.qr_url}`);
    setShow(!show);
  };

  const copyQRCode = async () => {
    var a = document.createElement("a"); //Create <a>
    a.href = "data:image/png;base64," + dashboardData.qr_code; //Image Base64 Goes here
    a.download = "qr.png"; //File name Here
    a.click();
    //document.body.removeChild(a);
  };

  const downloadBusinessCard = async () => {
    // download business card
    const pricingTableElmt = document.querySelector(".profileCard");
    if (!pricingTableElmt) return;
    // const canvas = await html2canvas(pricingTableElmt);
    // const dataURL = canvas.toDataURL("image/png");
    // downloadjs(dataURL, "profilecard.png", "image/png");

    htmlToImage
      .toJpeg(document.querySelector(".profileCard"), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "profilecard.jpeg";
        link.href = dataUrl;
        link.click();
      });
  };

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, request);
    } else {
      RequestProfileData();
    }
  }, [error, accounts]);

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <div className="dashboard-main">
          <div className="dashboard-sub-1">
            <div className="mainImage"></div>
          </div>
          {dashboardData.qr_url && (
            <div className="profileCard">
              <Profile qr_url={dashboardData.qr_url} />
            </div>
          )}
          <div className="dashboard-sub-2">
            <AlertMessage
              variant={"danger"}
              onClose={() => setShowMessage(false)}
              showMessage={showMessage}
            >
              Sorry Unable to process your request due to some technical problem
              right now, you may try again later!
            </AlertMessage>
            {dashboardData.qr_status === "NotFound" && (
              <AlertMessage
                variant={"danger"}
                onClose={() => setShowMessage(false)}
                showMessage={true}
              >
                Sorry Unable to find the email in database records!!
              </AlertMessage>
            )}
            {dashboardData.qr_status === "Disabled" && (
              <div>
                <ProfileContent />
                <p>
                  Looks like you've not yet generated QR code / URL for
                  accessing your Axiata Digital Business Card, Please use the
                  below link to Generate.
                </p>
                <Link to="#">
                  <button
                    style={{ marginTop: "20%" }}
                    onClick={generateQR}
                    className="button"
                  >
                    Generate the QR Code and URL
                  </button>
                </Link>
              </div>
            )}
            {dashboardData.qr_status === "Enabled" && (
              <div className="row">
                <ProfileContent />
                <p>
                  Please find the QR Code and URL for your Digital Business
                  Card. You may add them to your email signature or share
                  digitally.
                </p>
                <div className="row barcode-block">
                  <div className="barcodeBlock col-md-6">
                    <img
                      src={`data:image/png;base64,${dashboardData.qr_code}`}
                      className="barcodeImage"
                      alt="QR Code"
                    />
                  </div>
                  <div className="qrBlock col-md-6">
                    <Link to="#" className="qrBtn">
                      <Overlay
                        target={target1.current}
                        show={showTarget1}
                        onHide={() => setShowTarget1(false)}
                        placement="right"
                        rootClose={true}
                        delay={{ show: 250, hide: 400 }}
                      >
                        {(props) => (
                          <Tooltip id="overlay" {...props}>
                            Copied
                          </Tooltip>
                        )}
                      </Overlay>
                      <button
                        onClick={downloadBusinessCard}
                        style={{ marginTop: "20%", fontSize: "16px" }}
                        className="button"
                      >
                        Download Business Card
                      </button>
                      <button
                        onClick={copyQRCode}
                        style={{ marginTop: "4%", fontSize: "16px" }}
                        className="button"
                      >
                        Download QR Code
                      </button>
                    </Link>
                    <Link to="#" className="qrBtn">
                      <Overlay
                        target={target.current}
                        show={show}
                        onHide={() => setShow(false)}
                        placement="right"
                        rootClose={true}
                        delay={{ show: 250, hide: 400 }}
                      >
                        {(props) => (
                          <Tooltip id="overlay" {...props}>
                            Copied
                          </Tooltip>
                        )}
                      </Overlay>
                      <button
                        onClick={copyUrl}
                        style={{ marginTop: "6%", fontSize: "16px" }}
                        className="button"
                        ref={target}
                      >
                        Copy URL
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="row copyUrl">
                  <input
                    type="text"
                    value={`${dashboardData.qr_url}`}
                    disabled
                  />
                  <i className="bi bi-clipboard"></i>
                </div>
              </div>
            )}
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        You'll be redirect to microsoft login
        <Spinner animation="border" />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function Dashboard() {
  return (
    <PageLayout>
      <MainContent />
    </PageLayout>
  );
}
